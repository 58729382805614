<template>
  <div>
    <div class="">
      <div class="dx-card px-1 pb-1">
        <div style="height: calc(100vh - 130px);">
          <DxTabPanel 
           v-model:selected-index="homePageStore.selectedIndex"
            @selection-changed="homePageStore.onTabSelectionChanged"
            >
            <DxTabPanelItem title="Upload">
            <DxSplitter
              id="splitter" >
              <DxItemSplitter
                  :resizable="true"
                  :collapsible="true"
                  size="250px"
                  min-size="140px"
                  text="Left Pane"
              >

            <div class="pt-3">
            <DxProgressBar
               :value="homePageStore.progressLoaded" :max="homePageStore.progressTotal"
                :statusFormat="progress => `${(progress * 100).toFixed(2)}%`" 
              :visible="homePageStore.isLoading"
              :show-status="true"
            />
            </div>

            <DxFileUploader
              :select-button-text="'Select a file'"
              :label-text="'or drop it here'"
              :accept="'text/plain'"
              :multiple="false"
              :upload-mode="'useForm'"
              :on-value-changed="homePageStore.onFileSelected"
              show-file-list="false" 
            />

            </DxItemSplitter>
            <DxItemSplitter>
              <div style="height: calc(100vh - 175px);">
              <DxDataGrid
                :dataSource="homePageStore.dataSourceUpload"
                :showBorders="true"
                @exporting="homePageStore.onExportingWhitelist"
                @editing-start="homePageStore.onEditingStart"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-borders="true"
                :hover-state-enabled="true"
                :show-column-lines="true"
                :word-wrap-enabled="true"
                :row-alternation-enabled="false"
                :remote-operations="true"
                style="height:100%"
                :sorting="{mode: 'multiple'}"
                :scrolling="{mode: 'virtual',rowRenderingMode: 'virtual',columnRenderingMode: 'virtual',useNative: true}"
              >
              <DxColumnChooser :enabled="true"/>
              <DxExport :enabled="true"/>

                <DxPaging
                  :enabled="true"
                  :page-size="100"
                />
                <DxPager
                  :visible="true"
                  :showPageSizeSelector="false"
                  :showNavigationButtons="false"
                  :show-info="true"
                  :infoText="'Page {0} of {1} ({2} items)'"
                />
                <DxFilterRow :visible="true" apply-filter="auto"/>
                <DxScrolling mode="virtual"/>
                <DxSelection mode="single"/>
                <DxHeaderFilter :visible="false"/>
                <DxToolbar>
                  <DxItem location="before">
                    <span>Uploads History</span>
                  </DxItem>
                  <DxItem location="before">
                    
                  </DxItem>

                  <!-- Refresh Button -->
                  <DxItem location="center">
                    <DxButton
                        :icon="'refresh'"
                        type="default"
                        styling-mode="text"
                        text="Refresh"
                        @click="homePageStore.refreshUploadDatasource()"
                    />
                  </DxItem>

                  
                 
                  <DxItem location="after" name="columnChooserButton">
                  </DxItem>
                  <DxItem location="after" name="exportButton">
                  </DxItem>
                </DxToolbar>
               <DxEditing
                      mode="row"
                      :allowDeleting="true"
                    />
                <DxColumn
                  dataField="created_at"
                  caption="Created At"
                  dataType="date"
                  :width="150"
                  format="yyyy-MM-dd HH:mm:ss"
                />
                <DxColumn
                  dataField="id"
                  caption="Upload Id"
                />
                <DxColumn
                  dataField="name"
                  caption="Uploader Name"
                />
                <DxColumn
                  dataField="email"
                  caption="Uploader email"
                />

              </DxDataGrid>
              </div>
            </DxItemSplitter>
            <!-- <DxItemSplitter
                :resizable="true"
                :collapsible="true"
                size="220px"
                min-size="140px"
                text="Right Pane"
            >
            
            </DxItemSplitter> -->
          </DxSplitter>     
            </DxTabPanelItem>
            <DxTabPanelItem title="Phone Number">
              <div style="height: calc(100vh - 175px);">
                <DxDataGrid
                :dataSource="homePageStore.dataSourcePhoneNumber"
                :showBorders="true"
                @exporting="homePageStore.onExportingWhitelist"
                @editing-start="homePageStore.onEditingStart"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-borders="true"
                :hover-state-enabled="true"
                :show-column-lines="true"
                :word-wrap-enabled="true"
                :row-alternation-enabled="false"
                :remote-operations="true"
                style="height:100%"
                :sorting="{mode: 'multiple'}"
                :scrolling="{mode: 'virtual',rowRenderingMode: 'virtual',columnRenderingMode: 'virtual',useNative: true}"
              >
              <DxColumnChooser :enabled="true"/>
              <DxExport :enabled="true"/>

                <DxPaging
                  :enabled="true"
                  :page-size="100"
                />
                <DxPager
                  :visible="true"
                  :showPageSizeSelector="false"
                  :showNavigationButtons="false"
                  :show-info="true"
                  :infoText="'Page {0} of {1} ({2} items)'"
                />
                <DxFilterRow :visible="true" apply-filter="auto"/>
                <DxScrolling mode="virtual"/>
                <DxSelection mode="single"/>
                <DxHeaderFilter :visible="false"/>
                <DxToolbar>
                  <DxItem location="before">
                    <DxButton
                        :icon="'txtfile'"
                        styling-mode="text"
                        text="Dowload Text"
                        @click="homePageStore.downloadTextPhoneNumbers()"
                    />

                    <DxButton
                        :icon="'ordersbox'"
                        styling-mode="text"
                        text="Dowload CSV"
                        @click="homePageStore.downloadCsvPhoneNumbers()"
                    />

                    <DxButton
                        :icon="'hierarchy'"
                        styling-mode="text"
                        text="Dowload FILES"
                        @click="homePageStore.downloadSeparatedPhoneNumbers()"
                    />

                  </DxItem>
                  <DxItem location="before">
                    
                  </DxItem>

                  <!-- Refresh Button -->
                  <DxItem location="center">
                    <DxButton
                        :icon="'refresh'"
                        type="default"
                        styling-mode="text"
                        text="Refresh"
                        @click="homePageStore.refreshPhoneNumberDatasource()"
                    />
                  </DxItem>

                  <DxItem location="after" name="columnChooserButton">
                  </DxItem>
                  <DxItem location="after" name="exportButton">
                  </DxItem>
                </DxToolbar>
                <DxColumn
                  dataField="phone_number"
                  caption="Phone Number"
                />
                <DxColumn
                  dataField="operator"
                  caption="Operator"
                />
                <DxColumn
                  dataField="company_name"
                  caption="Company Name"
                />

                <DxColumn
                  dataField="area"
                  caption="Area"
                />
                <DxColumn dataField="created_at" caption="Tgl Upload" dataType="date" :width="150" format="dd/MM/yyyy" />

              </DxDataGrid>
              </div>
            </DxTabPanelItem>
          </DxTabPanel>

        </div>
      </div>
    </div>
    <DxLoadPanel
      v-model:visible="homePageStore.isDownloading"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"

        />
  </div>
</template>

<script setup>
import {DxSplitter, DxItem as DxItemSplitter} from 'devextreme-vue/splitter';
import DxFileUploader from 'devextreme-vue/file-uploader';
import { useHomePageStore } from '@/stores/views/home-page-store'; // Replace '@/stores/home-page-store' with the relative path to the 'home-page-store' file
import { DxButton } from 'devextreme-vue/button';
import DxTabPanel, { DxItem as DxTabPanelItem } from "devextreme-vue/tab-panel";
import DxProgressBar from 'devextreme-vue/progress-bar';
import DxLoadPanel from 'devextreme-vue/load-panel';

import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxScrolling,
  DxFilterRow, DxPager, DxHeaderFilter, 
  DxExport,
  DxItem,
  DxToolbar,
  DxColumnChooser,
  DxEditing,
  //DxForm,
  //DxPopup,
  //DxButton as DxButtonDatagrid,
} from "devextreme-vue/data-grid";
const homePageStore = useHomePageStore();
</script>
<style lang="scss">
</style>
