export default [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    role_id: [1,2]
  },
 /* {
    text: "Examples",
    icon: "folder",
    items: [
      {
        text: "Profile",
        path: "/profile"
      },
      {
        text: "Tasks",
        path: "/tasks"
      }
    ]
  }, 
  {
    text: 'Upload Page',
    path: '/upload-page',
    icon: 'attach'
  }*/
  {
    text: 'Company',
    path: '/company-page',
    icon: 'box',
    role_id: [1,2]
  }
  ];
