<template>
  <div>
    <div class="">
      <div class="dx-card px-1 pb-1">
        <div style="height: calc(100vh - 130px);">
              <DxDataGrid
                :dataSource="companyPageStore.dataSourceCompany"
                :showBorders="true"
                @exporting="companyPageStore.onExportingCompany"
                @editing-start="companyPageStore.onEditingStart"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-borders="true"
                :hover-state-enabled="true"
                :show-column-lines="true"
                :word-wrap-enabled="true"
                :row-alternation-enabled="false"
                :remote-operations="true"
                style="height:100%"
                :sorting="{mode: 'multiple'}"
                :scrolling="{mode: 'virtual',rowRenderingMode: 'virtual',columnRenderingMode: 'virtual',useNative: true}"
              >
              <DxColumnChooser :enabled="true"/>
              <DxExport :enabled="true"/>
                <DxPaging
                  :enabled="true"
                  :page-size="100"
                />
                <DxPager
                  :visible="true"
                  :showPageSizeSelector="false"
                  :showNavigationButtons="false"
                  :show-info="true"
                  :infoText="'Page {0} of {1} ({2} items)'"
                />
                <DxFilterRow :visible="true" apply-filter="auto"/>
                <DxScrolling mode="virtual"/>
                <DxSelection mode="single"/>
                <DxHeaderFilter :visible="false"/>

                <DxEditing
                        :allow-updating="true"
                        :allow-adding="true"
                        :allow-deleting="true"
                        mode="batch"
                        start-edit-action="dblClick"

                    />

                <DxToolbar>
                  <DxItem location="before">
                    <span>Company List</span>
                  </DxItem>
                  <DxItem location="before">
                    
                  </DxItem>

                  <!-- Refresh Button -->
                  <DxItem location="center">
                    <DxButton
                        :icon="'refresh'"
                        type="default"
                        styling-mode="text"
                        text="Refresh"
                        @click="companyPageStore.refreshDatasource()"
                    />
                  </DxItem>

                  <DxItem location="after" name="columnChooserButton">
                  </DxItem>
                  <DxItem location="after" name="exportButton">
                  </DxItem>
                </DxToolbar>
                    
                <DxColumn
                  dataField="id"
                  caption="ID"
                  :visible="false"
                />
                <DxColumn
                  dataField="company_name"
                  caption="Company Name"
                />

              </DxDataGrid>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompanyPageStore } from '@/stores/views/company-page-store'; // Replace '@/stores/home-page-store' with the relative path to the 'home-page-store' file
import { DxButton } from 'devextreme-vue/button';

import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxScrolling,
  DxFilterRow, DxPager, DxHeaderFilter, 
  DxExport,
  DxItem,
  DxToolbar,
  DxColumnChooser,
  DxEditing,
  //DxForm,
  //DxPopup,
  //DxButton as DxButtonDatagrid,
} from "devextreme-vue/data-grid";

const companyPageStore = useCompanyPageStore();

</script>
<style lang="scss">
</style>
