import { defineStore } from 'pinia';
import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/global-constants';
import api from '@/api';

export const useCompanyStore = defineStore(
    'companyStore', 
    () => {
        
        const selected = ref(null); 
        const latestAccessTime = ref(null);

        const companyCustomStore = new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = {};

                if (loadOptions.sort) {
                    params.sort = JSON.stringify(loadOptions.sort);
                }
                if (loadOptions.filter) {
                    params.filter = JSON.stringify(loadOptions.filter);
                }
                if (loadOptions.skip) {
                    params.skip = loadOptions.skip;
                }
                if (loadOptions.take) {
                    params.take = loadOptions.take;
                }

                const response = await axios.get(`${API_BASE_URL}/v1/companies`, {
                    params: params,
                    headers: {
                        Authorization: api.defaults.headers.common['Authorization'],
                        'Content-Type': 'application/json',
                    },
                });

                latestAccessTime.value = null;
                if (response.data) {
                    latestAccessTime.value = response.data.serverTime;
                    return {
                        data: response.data.data,
                        totalCount: response.data.totalCount,
                    };
                }

                throw 'Data loading error';
            },

            update: (key, values) => {

                return axios.patch(`${API_BASE_URL}/v1/companies/${key}`, values, {
                    headers: {
                        Authorization: api.defaults.headers.common['Authorization'],
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (response.data) {
                            return response.data;
                        }
                        throw new Error('Data updating error');
                    })
                    .catch(error => {
                        console.error('Error updating data:', error);
                        throw error;
                    });
            },
            insert: (values) => {
                return axios.post(`${API_BASE_URL}/v1/companies`, values, {
                    headers: {
                        Authorization: api.defaults.headers.common['Authorization'],
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (response.data) {
                            return response.data;
                        }
                        throw new Error('Data insertion error');
                    })
                    .catch(error => {
                        console.error('Error inserting data:', error);
                        throw error;
                    });
            },
            remove: (key) => {
                return axios.delete(`${API_BASE_URL}/v1/companies/${key}`, {
                    headers: {
                        Authorization: api.defaults.headers.common['Authorization'],
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (response.data) {
                            return response.data;
                        }
                        throw new Error('Data removal error');
                    })
                    .catch(error => {
                        console.error('Error removing data:', error);
                        throw error;
                    });
            },
        });

        function setSelected(data) {
            selected.value = data;
        }

        return {
            selected,
            latestAccessTime,
            companyCustomStore,
            setSelected,
        };
    }
);
