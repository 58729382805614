import { defineStore } from 'pinia';
import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/global-constants';
import api from '@/api';

export const usePhoneNumberStore = defineStore(
    'phoneNumberStore', 
    () => {

        const selected = ref(null); 
        const latestAccessTime = ref(null);
        const isPhoneNumberUnique = ref(true);
        const currentPhoneNumberParams = ref();

        const phoneNumberCustomStore = new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = {};

                if (loadOptions.sort) {
                    params.sort = JSON.stringify(loadOptions.sort);
                }
                if (loadOptions.filter) {
                    params.filter = JSON.stringify(loadOptions.filter);
                }
                if (loadOptions.skip) {
                    params.skip = loadOptions.skip;
                }
                if (loadOptions.take) {
                    params.take = loadOptions.take;
                }

                //add params isUnique. take from isPhoneNumberUnique
                if (isPhoneNumberUnique.value) {
                    params.isUnique = isPhoneNumberUnique.value;
                }

                currentPhoneNumberParams.value = params;

                const response = await axios.get(`${API_BASE_URL}/v1/phone-numbers`, {
                    params: params,
                    headers: {
                        Authorization: api.defaults.headers.common['Authorization'],
                        'Content-Type': 'application/json',
                    },
                });

                latestAccessTime.value = null;
                if (response.data) {
                    latestAccessTime.value = response.data.serverTime;
                    return {
                        data: response.data.data,
                        totalCount: response.data.totalCount,
                    };
                }

                throw 'Data loading error';
            },
        });

        async function getPhoneNumbersTotalCount() {
            const response = await axios.get(`${API_BASE_URL}/v1/phone-numbers`, {
            params: {
                take: 1,
                filter: currentPhoneNumberParams.value.filter,
            },
            headers: {
                Authorization: api.defaults.headers.common['Authorization'],
                'Content-Type': 'application/json',
            },
            });

            if (response.data) {
                return response.data.totalCount;
            }

            throw 'Error getting total count';
        }

        async function fetchPhoneNumbers(take = null, skip = null, fileType) {
            let params = {
                take:take,
                skip:skip,
                sort: currentPhoneNumberParams.value.sort,
                filter: currentPhoneNumberParams.value.filter,
                isUnique: isPhoneNumberUnique.value,
                file_type: fileType,
            };
        
            let headers = {
                Authorization: api.defaults.headers.common['Authorization'],
            };
        
            if (fileType === 'txt' || fileType === 'csv') {
                headers['Content-Type'] = 'text/plain';
            } else if (fileType === 'zip') {
                headers['Content-Type'] = 'application/zip';
            }
        
            return axios.get(`${API_BASE_URL}/v1/phone-numbers`, {
                params: params,
                headers: headers,
                timeout: 3 * 60 * 60 * 1000,
            });
        }

        function setSelected(data) {
            selected.value = data;
        }

        return {
            selected,
            latestAccessTime,
            phoneNumberCustomStore,
            setSelected,
            fetchPhoneNumbers,
            getPhoneNumbersTotalCount,
        };
    }
);
