import api from './api';

export default {
  _user: null,
  async loggedIn() {
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      api.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      if((await this.getUser()).isOk){
        return !!this._user;
      }else{
        return false;
      }
    } else {
      //console.log('authTokenFail');
      return false;
    }
  },

  async logIn(email, password) {
    try {
      const response = await api.post('login', {
        email,
        password
      });
      const { access_token } = response.data;
      localStorage.setItem('auth_token', access_token);
      api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      const userResponse = await this.getUser();
      return {
        isOk: true,
        data: userResponse.data
      };
    } catch (error) {
      return {
        isOk: false,
        message: error.response.data.message
      };
    }
  },

  async logOut() {
    try {
      //await api.post('logout');
      localStorage.removeItem('auth_token');
      delete api.defaults.headers.common['Authorization'];
      this._user = null;
      return {
        isOk: true
      };
    } catch (error) {
      return {
        isOk: false,
        message: error.response.data.message
      };
    }
  },

  async getUser() {
    try {
      const response = await api.get('user');
      this._user = response.data;
      return {
        isOk: true,
        data: response.data
      };
    } catch (error) {
      return {
        isOk: false,
        message: error.response.data.message
      };
    }
  },
  getUserRoleId() {
    if (this._user) {
      return this._user.role_id;
    }
    return null;
  },
  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
