import { defineStore } from 'pinia';
import DataSource from 'devextreme/data/data_source';
import { computed,ref } from 'vue';
import { usePhoneNumberStore } from '../apis/phone-number-store';
import { useUploadStore } from '../apis/upload-store';
import { useCompanyStore } from '../apis/company-store';
import axios from 'axios';
import { API_BASE_URL } from '@/global-constants';
import api from '@/api';
import notify from 'devextreme/ui/notify';
import {saveAs} from 'file-saver';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import {DateTime} from 'luxon';

export const useHomePageStore = defineStore('homePageStore', () => {
  const selectedIndex = ref(0);
  const progressLoaded = ref(0);
  const progressTotal = ref(0);
  const isLoading = ref(false);
  const isDownloading = ref(false);

  const dataSourcePhoneNumber = ref(
    new DataSource({
      requireTotalCount: true,
      store: usePhoneNumberStore().phoneNumberCustomStore,
    })
  );

  const dataSourceUpload = ref(
    new DataSource({
      requireTotalCount: true,
      store: useUploadStore().uploadCustomStore,
    })
  );

  const dataSourceCompany = ref(
    new DataSource({
      requireTotalCount: true,
      store: useCompanyStore().companyCustomStore,
    })
  );

  const refreshDatasource = () => {
    dataSourcePhoneNumber.value.reload();
    dataSourceUpload.value.reload();
  };


  const refreshPhoneNumberDatasource = () => {
    dataSourcePhoneNumber.value.reload();
  };

  const refreshUploadDatasource = () => {
    dataSourceUpload.value.reload();
  };

  const initialized = () => {
    dataSourcePhoneNumber.value.reload();
    dataSourcePhoneNumber.value.reload();
  };
  
  /*
  async function downloadTextPhoneNumbers() {
    try {
        const response = await usePhoneNumberStore().fetchPhoneNumbers("TXT");
        const blob = new Blob([response.data], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `phone_numbers_${Date.now()}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading phone numbers:', error);
    }
}
*/

async function downloadTextPhoneNumbers() {
  
  isDownloading.value = true;

  try {
      console.log('Starting download process...');

      // Step 1: Get the total count of phone numbers
      const totalCount = await usePhoneNumberStore().getPhoneNumbersTotalCount();
      console.log('Total count:', totalCount);

      // Step 2: Calculate the number of chunks needed
      const chunkSize = 100000;
      const numChunks = Math.ceil(totalCount / chunkSize);
      const batchSize = 10; // Number of promises to run in parallel

      // Step 3: Initialize an empty array to store the chunks
      const chunks = [];

      // Step 4: Create batches of promises and fetch each batch sequentially
      for (let i = 0; i < numChunks; i += batchSize) {
          const batchPromises = [];
          for (let j = 0; j < batchSize && (i + j) < numChunks; j++) {
              const skip = (i + j) * chunkSize;
              batchPromises.push(usePhoneNumberStore().fetchPhoneNumbers(chunkSize, skip, "TXT"));
          }
          const batchResponses = await Promise.all(batchPromises);
          batchResponses.forEach(response => {
              const lines = response.data.trim().split('\n'); // Trim to remove any extra newlines
              chunks.push(...lines);
              console.log('Fetched chunk:', chunks.length);
          });
      }

      // Step 5: Combine the chunks into a single Blob
      const combinedData = chunks.join('\n');
      const blob = new Blob([combinedData], { type: 'text/plain' });

      // Step 6: Trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `phone_numbers_${Date.now()}.txt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

  } catch (error) {
      console.error('Error downloading phone numbers:', error);
  }
  isDownloading.value = false;
}

async function downloadCsvPhoneNumbers() {
  isDownloading.value = true;
  try {
      console.log('Starting download process...');

      // Step 1: Get the total count of phone numbers
      const totalCount = await usePhoneNumberStore().getPhoneNumbersTotalCount();

      // Step 2: Calculate the number of chunks needed
      const chunkSize = 50000;
      const numChunks = Math.ceil(totalCount / chunkSize);
      const batchSize = 10; // Number of promises to run in parallel

      // Step 3: Initialize an empty array to store the chunks
      const chunks = [];

      // Step 4: Create batches of promises and fetch each batch sequentially
      for (let i = 0; i < numChunks; i += batchSize) {
          const batchPromises = [];
          for (let j = 0; j < batchSize && (i + j) < numChunks; j++) {
              const skip = (i + j) * chunkSize;
              batchPromises.push(usePhoneNumberStore().fetchPhoneNumbers(chunkSize, skip, "CSV"));
          }
          const batchResponses = await Promise.all(batchPromises);
          batchResponses.forEach(response => {
              const lines = response.data.trim().split('\n'); // Trim to remove any extra newlines
              chunks.push(...lines);
          });
      }

      // Step 5: Combine the chunks into a single Blob
      const combinedData = chunks.join('\n');
      const blob = new Blob([combinedData], { type: 'text/csv' });

      // Step 6: Trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `phone_numbers_${Date.now()}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error('Error downloading phone numbers:', error);
  }
  isDownloading.value = false;
}

async function downloadSeparatedPhoneNumbers() {
  isDownloading.value = true;
  try {

      // Step 1: Get the total count of phone numbers
      const totalCount = await usePhoneNumberStore().getPhoneNumbersTotalCount();

      // Step 2: Calculate the number of chunks needed
      const chunkSize = 100000;
      const numChunks = Math.ceil(totalCount / chunkSize);
      const batchSize = 10; // Number of promises to run in parallel

      // Step 3: Initialize an empty array to store the chunks
      const allLines = [];

      // Step 4: Create batches of promises and fetch each batch sequentially
      for (let i = 0; i < numChunks; i += batchSize) {
          const batchPromises = [];
          for (let j = 0; j < batchSize && (i + j) < numChunks; j++) {
              const skip = (i + j) * chunkSize;
              batchPromises.push(usePhoneNumberStore().fetchPhoneNumbers(chunkSize, skip, "TXT"));
          }
          const batchResponses = await Promise.all(batchPromises);
          batchResponses.forEach(response => {
              const lines = response.data.trim().split('\n'); // Trim to remove any extra newlines
              allLines.push(...lines);
          });
      }

      // Step 5: Define the chunk size for the files
      const fileChunkSize = 1000000;

      // Step 6: Calculate the number of files needed
      const numFiles = Math.ceil(allLines.length / fileChunkSize);

      // Step 7: Create and download each file
      for (let i = 0; i < numFiles; i++) {
          // Slice the lines array to create file chunks
          const fileChunk = allLines.slice(i * fileChunkSize, (i + 1) * fileChunkSize);

          // Create the content of the text file
          const chunkContent = fileChunk.join('\n');

          // Create a Blob from the chunk content
          const blob = new Blob([chunkContent], { type: 'text/plain' });

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element to download the file
          const a = document.createElement('a');
          a.href = url;
          a.download = `phone_numbers_part_${i + 1}_${Date.now()}.txt`;

          // Append the link to the document and click it to trigger the download
          document.body.appendChild(a);
          a.click();

          // Clean up the URL object and remove the link element
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

      }

  } catch (error) {
      console.error('Error downloading phone numbers:', error);
  }
  isDownloading.value = false;
}

  //const uploadUrl = ref(`${API_BASE_URL}/v1/phone-numbers/upload`);
  const uploadUrl = ref(`${API_BASE_URL}/v1/phone-numbers/upload-text`);

  const uploadHeaders = ref({
      Authorization: api.defaults.headers.common['Authorization'],
      'Content-Type': 'multipart/form-data',
  });

  const uploadCustomData = ref({
      company_id: 1, // Set your company_id dynamically if necessary
  });

  const onTabSelectionChanged = (e) => {
    console.log('Tab changed to:', e);
    switch (e.addedItems[0].title) {
      case 'Phone Number':
        selectedIndex.value = 1;
        dataSourcePhoneNumber.value.reload();
        break;
      default:
        dataSourceUpload.value.reload();
        selectedIndex.value = 0;
      break;
    }
    refreshDatasource();
  };

  const progressPercentage = computed(() => {
    if (progressTotal.value === 0) {
      return 0;
    }
    const percentage = (progressLoaded.value / progressTotal.value) * 100;
    return percentage.toFixed(2);
  });

const onFileSelected = async (e) => {
    const file = e.value[0];

    if (!file) {
        console.error('No file selected');
        return;
    }

    const formData = new FormData();
    
    // Append company_id and file to formData
    formData.append('company_id', uploadCustomData.value.company_id);
    formData.append('file', file);

    // To verify if FormData has been populated correctly
    for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
    }

    try {
      
      isLoading.value = true;  
      const response = await axios.post(uploadUrl.value, formData, {
            headers: uploadHeaders.value,
            onUploadProgress: (progressEvent) => {
                console.log(`Progress: ${progressEvent.loaded} of ${progressEvent.total}`);
                progressLoaded.value = progressEvent.loaded;
                progressTotal.value = progressEvent.total;
            },
        });

        refreshDatasource();
        

      isLoading.value = false;
        notify({
          message:'Upload Sukses, Silahkan Tunggu Proses Selesai',
          position: 'bottom center',
      }, 'success', 3000);
        console.log('File uploaded successfully', response);
    } catch (error) {
        console.error('File upload failed', error);
    }
  };

  const onExportingWhitelist = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('sheet1');

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            const date = DateTime.local(); // Gets current date and time
            const formattedDate = date.toFormat('yyyyMMdd');
            const fileName = `whitelist_nomor_ponsel_${formattedDate}.xlsx`;
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
        });
    });

    e.cancel = true;
  };

  return {
    isLoading,
    progressPercentage,
    progressLoaded,
    progressTotal,
    selectedIndex,
    onTabSelectionChanged,
    dataSourcePhoneNumber,
    dataSourceUpload,
    dataSourceCompany,
    onFileSelected,
    refreshDatasource,
    refreshPhoneNumberDatasource,
    refreshUploadDatasource,
    initialized,
    onExportingWhitelist,
    downloadTextPhoneNumbers,
    downloadCsvPhoneNumbers,
    downloadSeparatedPhoneNumbers,
    isDownloading,
  };
});