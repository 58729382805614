import { defineStore } from 'pinia';
import CustomStore from 'devextreme/data/custom_store';
import { ref } from 'vue';
import axios from 'axios';
import { API_BASE_URL } from '@/global-constants';
import api from '@/api';

export const useUploadStore = defineStore(
    'uploadStore', 
    () => {
        
        const selected = ref(null); 
        const latestAccessTime = ref(null);

        const uploadCustomStore = new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
            let params = {};

            if (loadOptions.sort) {
                params.sort = JSON.stringify(loadOptions.sort);
            }
            if (loadOptions.filter) {
                params.filter = JSON.stringify(loadOptions.filter);
            }
            if (loadOptions.skip) {
                params.skip = loadOptions.skip;
            }
            if (loadOptions.take) {
                params.take = loadOptions.take;
            }

            console.log('uploads params',params);

            const response = await axios.get(`${API_BASE_URL}/v1/uploads`, {
                params: params,
                headers: {
                Authorization: api.defaults.headers.common['Authorization'],
                'Content-Type': 'application/json',
                },
            });

            latestAccessTime.value = null;
            
            console.log('uploads response',response);

            if (response.data) {
                latestAccessTime.value = response.data.serverTime;
                return {
                data: response.data.data,
                totalCount: response.data.totalCount,
                };
            }

            throw 'Data loading error';
            },
            remove: async (key) => {
            const response = await axios.delete(`${API_BASE_URL}/v1/uploads/${key}`, {
                headers: {
                Authorization: api.defaults.headers.common['Authorization'],
                'Content-Type': 'application/json',
                },
            });

            if (response.data) {
                return response.data;
            }

            throw 'Deletion error';
            },
        });

        function setSelected(data) {
            selected.value = data;
        }

        return {
            selected,
            latestAccessTime,
            uploadCustomStore,
            setSelected,
        };
    }
);
