import CompanyPage from './views/company-page';
//import UploadPage from './views/upload-page';
import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import Profile from "./views/profile-page";
//import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";

function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        requiredRoles: [1,2],
        layout: defaultLayout
      },
      component: Home
    },{
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        requiredRoles: [1,2],
        layout: defaultLayout
      },
      component: Profile
    },
   /*
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },*/
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Whitelist Manager",
        description: "Mentari Reload"

      },
      component: loadView("login-form")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
/*    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },*/
    {
      path: "/",
      redirect: "/home"
    },/*
    {
      path: "/recovery",
      redirect: "/home"
    },*/
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }, /*
    {
      path: "/upload-page",
      name: "upload-page",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UploadPage
    }*/
    {
      path: "/company-page",
      name: "company-page",
      meta: {
        requiresAuth: true,
        requiredRoles: [1,2],
        layout: defaultLayout
      },
      component: CompanyPage
    }
  ],
  history: createWebHashHistory()
});

// In your beforeEach navigation guard
router.beforeEach(async (to, from, next) => {
  var isLoggedIn = await auth.loggedIn();
  //var userRole = isLoggedIn ? auth._user.role_id : null;
  var userRole = auth.getUserRoleId();

  if (to.name === "login-form" && isLoggedIn) {
    next({name: "home"});
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({
        name: "login-form",
        query: {redirect: to.fullPath}
      });
    } else {
      // If the route requires certain roles
      if (to.matched.some(record => record.meta.requiredRoles)) {
        // Check if user has necessary role
        if (to.matched.some(record => record.meta.requiredRoles.includes(userRole))) {
          next();
        } else {
          next({name: "home"}); // Redirect to home or any other page
        }
      } else {
        next();
      }
    }
  } else {

    next();
  }
});

export default router;