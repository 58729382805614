import { defineStore } from 'pinia';
import DataSource from 'devextreme/data/data_source';
import { ref } from 'vue';
import { useCompanyStore } from '../apis/company-store';
import {saveAs} from 'file-saver';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {Workbook} from 'exceljs';
import {DateTime} from 'luxon';

export const useCompanyPageStore = defineStore('companyPageStore', () => {

  const dataSourceCompany = ref(
    new DataSource({
      requireTotalCount: true,
      store: useCompanyStore().companyCustomStore,
    })
  );

  const refreshDatasource = () => {
    dataSourceCompany.value.reload();
  };

  const initialized = () => {
    dataSourceCompany.value.reload();
  };
  
  const onExportingCompany = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('sheet1');

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            const date = DateTime.local(); // Gets current date and time
            const formattedDate = date.toFormat('yyyyMMdd');
            const fileName = `company_${formattedDate}.xlsx`;
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
        });
    });

    e.cancel = true;
  };

  return {
    dataSourceCompany,
    refreshDatasource,
    initialized,
    onExportingCompany,
  };
});